import Glide from '@glidejs/glide';


export function sliderController() {
    new Glide('.glide', {
        type: 'carousel', // или 'slider' для простого слайдера
        startAt: 0,
        perView: 1, // Количество видимых слайдов
        // autoplay: 3000, // Автоплей через 3 секунды (можно убрать)
        hoverpause: true, // Пауза при наведении
    }).mount();
};

